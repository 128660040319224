import { Portuguese } from "flatpickr/dist/l10n/pt.js"

const datepicker = {
  value: null,
  instance: null,

  async init() {
      this.$watch('value', value => this.instance.setDate(value, false));

      await this.$nextTick();

      this.instance = new flatpickr(this.$el, {
          altInput: true,
          altFormat: "d/m/Y",
          allowInput: true,
          locale: Portuguese,
      })
  }
}

export default datepicker