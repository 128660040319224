import axios from "axios";
import TomSelect from "tom-select";

const defaultValues= {
    persist: false,
    maxOptions: null,
    render: {
        option_create: (data, escape) =>
            `<div class="create">Adicionar <strong>${escape(data.input.toUpperCase())}</strong>&hellip;</div>`,

        no_results: (data, escape) =>
            `<div class="no-results">Sem reultados para "${escape(data.input.toUpperCase())}"</div>`,

        loading: () =>
            `<div>
                <div class="spinner"></div>
                <div>Carregando&hellip;</div>
            </div>`
    }
}

const isIterable = obj => obj[Symbol.iterator] || obj[Symbol.asyncIterator]

const parseResponseData = data => {
    if (!isIterable(data)) return data

    return data.map(item =>
        (typeof item === 'object')
        ? item
        : { value: item, text: item }
    )
}

const parseOpts = (opts) => {
    if (opts.remoteURI) {
        opts.load = (query, callback) =>
            axios.get(opts.remoteURI(query))
                .then(res => parseResponseData(res.data))
                .then(data => callback(data))
                .catch(err => console.error(err))
    }

    return { ...defaultValues, ...opts }
}

const useSelect = (selector, opts = {}) => new TomSelect(selector, parseOpts(opts))

export default useSelect
