import useSelect from "@/composables/useSelect";
import axios from "axios";

const store = {
    driverList: null,
    services: null,

    init() {
        this.driverList = useSelect(this.$refs.driverSelect, {
            maxItems: 1,
            valueField: 'id',
            labelField: 'nickname',
            searchField: 'nickname',
        })

        this.$watch('services', (id, old) => {
            this.$dispatch('service-change', id)

            if (Number(id) < 1) return
            if (this.driverList.getValue() && !old) return

            this.driverList.clear()
            this.driverList.disable()

            axios.get(`/service/${id}/drivers`)
                .then(res => {
                    this.driverList.clearOptions()
                    this.driverList.addOptions(res.data)
                    this.driverList.enable()
                })
        })
    }
}

export default store
